import { createApp } from "vue";
import { createHead } from "@unhead/vue";

import App from "./App.vue";
import router, {setCanonical, setSeoMeta} from "./router.js";

const defaultMeta = {
	title: `A pikatool.com Page`,
	keywords: `Tools, Online Tools, Streamer Tools, Streamer Resources, Free, Simple, Fast`,
	ogTitle: `A pikatool.com Page`,
	description: `A page for online tools`,
	ogDescription: `A page for online tools`,
};

router.beforeEach((to, from, next) => {

	if(to.name !== from.name){
		// Find the nearest route element with meta tags.
		const metaRoute = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

		if(metaRoute){
			const meta = metaRoute.meta;

			setSeoMeta({
				title: meta.title,
				keywords: meta.keywords || null,
				ogTitle: meta.ogTitle || meta.title,
				description: meta.description || null,
				ogDescription: meta.ogDescription || (meta.description? meta.description : null),
				// ogImage: meta.ogImage || null,
			});
		} else {
			setSeoMeta(defaultMeta);
		}
	}

	setCanonical(`https://${window.location.host}${to.path}`);

	next();
});

import mixin from "@/mixin";

createApp(App)
	.use(router)
	.use(createHead())
	.mixin(mixin)
	.mount('#app');
