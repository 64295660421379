<template>
    <header>
        <div class="container-big px-3 h-100">
            <div class="row h-100">
                <div class="col-4 px h-100 header-padding">
                    <router-link :to="{name: 'home'}" >
                        <img class="d-md-inline-block d-none header-logo h-100" src="/media/img/logo.svg" alt="Pikatool logo"/>
                        <img class="d-md-none d-inline-block header-logo header-logo-mobile position-relative" src="/media/img/logo_icon.svg" alt="Pikatool logo icon"/>
                    </router-link>

                    <!-- Beta -->
                    <Badge class="fs-12 d-none d-md-inline header-logo-badge badge-white" style="transform:translateY(-0.4em);">Beta</Badge>
                </div>
                <div class="col-4 h-100 header-padding-sm">
                    <router-link :to="{name: 'tools'}" class="header-tool-button">
                        <span v-if="is_tool_page" class="d-none d-md-inline">Switch tool</span>

                        <span v-else class="d-none d-md-inline">Pi<span style="color:rgba(0,0,0,0.5);">c</span>k a tool</span>
                        <CaretIcon/>
                    </router-link>
                </div>
                <div class="col-4 px h-100 header-padding-sm d-flex align-items-center justify-content-end">
                    <Auth ref="auth">
                        <template #loggedOut>
                            <button @click="$refs.auth.logIn()" type="button" class="d-none d-md-block btn btn-white">
                                <TwitchLogo/>
                                <span>Sign in</span>
                            </button>

                            <div @click="$refs.auth.logIn()" class="d-flex d-md-none align-items-center fs-32">
                                <UserCircleIcon class="txt-primary m-0"/>
                            </div>
                        </template>
                        <template v-if="mounted" #loggedIn>
                            <Dropdown class="header-profile-container">
                                <img class="header-profile-image" :src="$refs.auth.user_data.avatar_url" />

                                <template #options>
                                    <DropdownSection>
                                        <div class="fs-18 text-center icon-text">
                                            <TwitchLogo/> {{$refs.auth.user_data.displayName}}
                                        </div>
                                    </DropdownSection>
                                    <DropdownOption @click="$refs.auth.logOut()"><CloseIcon/><span>Log out</span></DropdownOption>
                                </template>
                            </Dropdown>
                        </template>
                    </Auth>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import CaretIcon from "@/components/icons/CaretIcon.vue";
import Badge from "@/components/Badge.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import Auth from "@/components/Auth.vue";
import TwitchLogo from "@/components/icons/logo/TwitchLogo.vue";
import Dropdown from "@/components/input/Dropdown.vue";
import DropdownOption from "@/components/input/Dropdown/DropdownOption.vue";
import DropdownSection from "@/components/input/Dropdown/DropdownSection.vue";
import UserCircleIcon from "@/components/icons/UserCircleIcon.vue";

export default {
    name: "Header",
    components: {
        UserCircleIcon,
        DropdownSection, DropdownOption, Dropdown, TwitchLogo, Auth, CloseIcon, Badge, CaretIcon},
    computed: {
        is_tool_page: function(){
            return this.$route.path.startsWith('/tool/');
        }
    }
}
</script>

<style>
header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 3.2rem;
    background-color: var(--content-background-2);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    box-shadow: 0 0 1rem var(--like-black);
}
header .header-padding{
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
header .header-padding-sm{
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

header .header-logo{
    transition: 0.16s ease;
}
header .header-logo-mobile{
    height: 1.6rem;
}
header .header-logo:not(.header-logo-mobile):hover{
    transform: scale(0.96);
}
header .header-logo-badge{
    position: absolute;
    bottom: 0.6rem;
    margin-left: 0.4rem;
}

header .header-tool-button{
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
    background-color: var(--primary);
    border-radius: 0.4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 0.8rem;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--like-black);

    transition: 0.2s ease;
}
header .header-tool-button:hover{
    background-color: white;
}
header .header-tool-button .icon{
    transform: rotate(90deg);
    height: 1.4em;
    margin-top: -0.32rem;
}
header .header-tool-button:hover .icon{
    animation: header-tool-icon-animation 1.6s ease infinite;
}
@keyframes header-tool-icon-animation {
    20%{
        transform: rotate(90deg) translateX(-0.1em);
    }
    40%{
        transform: rotate(90deg) translateX(0.2em);
    }
}

header .header-profile-container,
header .header-profile-image{
    height: 100%;
}
header .header-profile-image{
    border-radius: 50%;
    border: var(--min-border-width) solid var(--primary);
    background-color: var(--like-black);
}
header .header-profile-image:hover{
    cursor: pointer;
}
</style>