import moment from "moment";
import axios from "axios";

export const random = (min, max) => {
    if(typeof min !== "number") min = parseInt(min);
    if(typeof max !== "number") max = parseInt(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
    data: () => ({
        defaults: {
            avatar_url: `/media/img/user_default.jpg`
        },
        mounted: false
    }),
    methods: {
        sleep(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        time(ms = false){
            const time = (new Date()).getTime();
            return ms? time : parseInt(time / 1000);
        },
        random,
        getRandomItem(array){
            return array[Math.floor(Math.random()*array.length)];
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
        fallbackCopyTextToClipboard(text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(() => {
                console.log('Async: Copying to clipboard was successful!');
            }, err => {
                console.error('Async: Could not copy text: ', err);
            });
        },
        isPartOfElement(needle, haystack){
            return this.isPartOfElements(needle, [haystack]);
        },
        isPartOfElements(needle, haystack){
            while(true){
                if(haystack.includes(needle)) {
                    return true;
                } else if(needle.parentElement === document.documentElement || !needle.parentElement){
                    return false;
                } else {
                    needle = needle.parentElement;
                }
            }
        },
        getElementPosition(el) {
            const rect = el.getBoundingClientRect();
            return {
                left: rect.left + window.scrollX,
                top: rect.top + window.scrollY
            };
        },
        scrollTo(top){
            window.scrollTo({
                top: top,
                behavior: "smooth",
            });
        },
        apiRequest(url, config = {}){
            return new Promise((resolve, reject) => {
                const handleResponse = (data) => resolve((typeof data === 'object')? data : false);

                if(this.$root.auth){
                    if(!('headers' in config)) config.headers = {};
                    config.headers['Authorization'] = `Bearer ${this.$root.auth.access_token}`;
                }

                axios.request({
                    ...config,
                    url
                }).then(response => {
                    handleResponse(response.data);
                }).catch(error => {
                    if(error.response && error.response.data && typeof error.response.data === 'object' && 'success' in error.response.data){
                        handleResponse(error.response.data);
                    } else {
                        reject(error);
                    }
                });
            });
        },
        apiPostRequest(url, data, config = {}){
            return this.apiRequest(url, {...config, method: 'POST', data: data});
        },

        saveLocalConfig(key, config){
            if(config === null){
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(config));
            }
        },
        getLocalConfig(key, defaultValue = null){
            const value = localStorage.getItem(key);
            if(value){
                try {
                    return JSON.parse(value);
                } catch (error) {
                    localStorage.removeItem(key);
                }
            }
            return defaultValue;
        },
        insertLocalConfig(key, targetObject){
            const value = localStorage.getItem(key);
            if(value){
                try {
                    const config = JSON.parse(value);

                    for(const [configKey, defaultValue] of Object.entries(targetObject)){
                        if(configKey in config){
                            if(defaultValue && typeof defaultValue === 'object' && !Array.isArray(defaultValue)){
                                targetObject[configKey] = {...defaultValue, ...config[configKey]};
                            } else {
                                targetObject[configKey] = config[configKey];
                            }
                        }
                    }
                } catch (error) {
                    localStorage.removeItem(key);
                }
            }
        },

        preloadImage(src, rejectError = true){
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = resolve;
                image.onerror = rejectError? reject : resolve;
                image.src = src;
            });
        },
        preloadImages(array, onUpdate = null){
            return new Promise((resolve) => {
                const queue = [...array];

                const runningTasks = [];
                let _count = 1;

                const next = async (taskId) => {
                    if(queue.length === 0) {
                        if(runningTasks.length === 0) resolve(); // Finish
                        return;
                    }
                    runningTasks.push(taskId); // Add task id

                    const src = queue[0];
                    queue.splice(0, 1);

                    await this.preloadImage(src, false);
                    if(onUpdate) onUpdate(_count++, array.length, src);

                    runningTasks.splice(runningTasks.indexOf(taskId), 1); // Remove task id
                    next(taskId);
                }

                for(let i = 0; i < 8; i++){
                    next(i);
                }
            });
        },

        sortCompare(objectKey, reverse = false) {
            return reverse? (a, b) => {
                if(b[objectKey] < a[objectKey]){
                    return -1;
                }
                if(b[objectKey] > a[objectKey]){
                    return 1;
                }
                return 0;
            } : (a, b) => {
                if(a[objectKey] < b[objectKey]){
                    return -1;
                }
                if(a[objectKey] > b[objectKey]){
                    return 1;
                }
                return 0;
            }
        }
    },
    computed: {
        window: function(){
            return window;
        },
        console: function(){
            return console;
        },
        moment: function(){
            return moment;
        }
    },
    mounted(){
        this.mounted = true;
    },
    unmounted() {
        if(this.task){
            if(this.task.timeout){
                for(const task of Object.values(this.task.timeout)){
                    clearTimeout(task);
                }
            }
            if(this.task.interval){
                for(const task of Object.values(this.task.interval)){
                    clearInterval(task);
                }
            }
            if(this.task.socket){
                for(const socket of Object.values(this.task.socket)){
                    if(socket.connected){
                        socket.disconnect();
                    }
                }
            }
            if(this.task.unsub){
                for(const unsub of Object.values(this.task.unsub)){
                    if(typeof unsub === 'function'){
                        unsub();
                    }
                }
            }
        }
    }
};
