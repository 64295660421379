<template>
    <div :class="[`py-container`, {'pt-0': breadcrumbs.length > 0, 'container': container}]">

        <Breadcrumbs v-if="breadcrumbs.length > 0" :items="breadcrumbs" class="breadcrumbs"/>

        <slot v-if="$slots.beforeContentBox" name="beforeContentBox"></slot>

        <div v-if="content_box" class="content-box content-box-outbreak content-box-section">
            <template v-if="!page_loading">

                <div v-if="title" class="d-flex justify-content-center" style="margin-bottom:4rem;">
                    <h1 class="text-center heading-underline">{{title}}</h1>
                </div>

                <div v-if="$slots.intro" class="mb-5">
                    <slot name="intro"></slot>
                </div>

                <div v-if="!auto_sidebar" :class="[{'content-page': !custom_content}]">
                    <slot></slot>
                </div>

                <div v-else :class="[{'content-page': !custom_content}]">
                    <div class="d-flex">
                        <div ref="content" class="content-page-stretch pe-xl-5">
                            <slot></slot>
                        </div>
                        <div class="d-none d-xl-block">
                            <div class="sticky-sidebar">
                                <nav class="heading-sidebar limited-sm">
                                    <div class="fs-18 weight-bold text-uppercase icon-text px-3 pt-4">
                                        <TableListIcon/>
                                        <span>Page content</span>
                                    </div>

                                    <div class="pt-3 pb-4 px-2">
                                        <ul>
                                            <li v-for="(sidebarTitle, index) of sidebarTitles" :key="index" @click="scrollToHeading(sidebarTitle)" :class="[`heading-sidebar-item heading-sidebar-item-${sidebarTitle.tag}`, {'active': sidebarTitle.active}]">
                                                {{sidebarTitle.title}}
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                                <slot name="afterSidebar"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <PagePlaceholders v-else/>
        </div>

        <slot v-if="!page_loading" name="outer-content-box"/>
    </div>
</template>

<script>
import TableListIcon from "@/components/icons/TableListIcon.vue";
import AngleIcon from "@/components/icons/AngleIcon.vue";
import PagePlaceholders from "@/components/placeholder/PagePlaceholders.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
    name: "Page",
    components: {Breadcrumbs, PagePlaceholders, AngleIcon, TableListIcon},
    props: {
        title: String,
        breadcrumbs: {
            type: Array,
            default: []
        },
        page_loading: {
            type: Boolean,
            default: false
        },
        auto_sidebar: {
            type: Boolean,
            default: false
        },
        custom_content: {
            type: Boolean,
            default: false
        },
        content_box: {
            type: Boolean,
            default: true
        },
        container: {
            type: Boolean,
            default: true
        },
        auto_scroll_top: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        sidebarTitles: []
    }),
    watch: {
        '$root.body.width': function(){
            this.updateAutoSidebar();
        },
        '$root.body.height': function(){
            this.updateAutoSidebar();
        },
        '$root.body.scroll': function(){
            this.updateAutoSidebarActiveElement();
        },
        '$route.params': function(){
            if(this.auto_scroll_top){
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        }
    },
    methods: {
        updateAutoSidebarActiveElement(){
            if(this.auto_sidebar){
                const scroll = this.$root.body.scroll;

                for(const sidebarTitle of this.sidebarTitles){
                    if(scroll > this.getActivationPosition(sidebarTitle.pos.top)){
                        sidebarTitle.active = true;
                    } else {
                        sidebarTitle.active = false;
                    }
                }
            }
        },
        updateAutoSidebar(){
            if(this.$refs.content){
                const titles = [];

                for(const el of this.$refs.content.querySelectorAll(`h2, h3, h4, h5`)){
                    // const span = el.querySelector(`span`);

                    titles.push({
                        title: el.innerText, // (span)? span.innerText : el.innerText,
                        pos: this.getElementPosition(el),
                        tag: el.tagName.toLowerCase(),
                        el
                    });
                }
                this.sidebarTitles = titles

                this.updateAutoSidebarActiveElement();
            }
        },

        scrollToHeading(sidebarTitle){
            sidebarTitle.el.classList.add(`scroll-to-marker`);
            setTimeout(() => sidebarTitle.el.classList.remove(`scroll-to-marker`), 1520);

            window.scrollTo({
                top: this.getActivationPosition(sidebarTitle.pos.top) + 1,
                behavior: "smooth",
            });
        },
        getActivationPosition(topPosition){
            return topPosition - (this.$root.body.height * 0.4);
        },
    },
    mounted() {
        this.updateAutoSidebar();
    }
}
</script>

<style>
.content-page-stretch{
    flex:1;
    max-width:100%;
}

.content-page{
    font-size: 0.9rem;
}
.content-page p{
    margin: 1em 0;
}
.content-page a:link,
.content-page a:visited{
    color: var(--primary);
}
.content-page a:link:hover,
.content-page a:visited:hover{
    text-decoration: underline;
}
.content-page h2,
.content-page h3,
.content-page h4,
.content-page h5,
.content-page h6{
    color: var(--like-white);
}
.content-page h2{
    font-size: 1.6rem;
}
.content-page h3{
    font-size: 1.4rem;
}
.content-page h4{
    font-size: 1.2rem;
}
.content-page h5{
    font-size: 1.1rem;
}
.content-page ul{
    padding-left: 1em;
}



.sticky-sidebar{
    position: -webkit-sticky;
    position: sticky;
    top: calc(3rem + 1.6rem);
}
.heading-sidebar{
    background-color: var(--content-background-2);
    border-radius: 0.8rem;
}
.heading-sidebar ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.heading-sidebar .heading-sidebar-item{
    color: var(--like-dark-white);
    font-size: 0.8rem;
    font-weight: 200;
    padding: 0.6em 0.8em;
    transition: 0.1s ease;
    border-radius: 0.4em;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.heading-sidebar .heading-sidebar-item.heading-sidebar-item-h3{
    padding-left: 1.6em;
}
.heading-sidebar .heading-sidebar-item.heading-sidebar-item-h4{
    padding-left: 2.4em;
}
.heading-sidebar .heading-sidebar-item.heading-sidebar-item-h5{
    padding-left: 3.2em;
}
.heading-sidebar .heading-sidebar-item.active{
    color: var(--like-white);
    font-weight: normal;
}
.heading-sidebar .heading-sidebar-item:hover{
    cursor: pointer;
    color: var(--primary);
    background-color: var(--content-background-3);
}

.scroll-to-marker{
    position: relative;
    z-index: 1;
}
.scroll-to-marker::after{
    content: "";
    position: absolute;
    z-index: -1;
    top: -0.2em;
    left: -0.4em;
    width: calc(100% + 0.8em);
    height: calc(100% + 0.4em);
    background-color: rgba(0,225,225,0.16);
    border-radius: 0.4em;

    animation: scroll-to-marker-animation 1.5s linear forwards;
}
@keyframes scroll-to-marker-animation {
    0%{
        opacity: 0;
        box-shadow: 0 0 0 transparent;
    }
    20%{
        opacity: 1;
        box-shadow: 0 0 0 transparent;
    }
    100%{
        opacity: 0;
        box-shadow: 0 0 0 1.6rem rgba(0,225,225,0.16);
    }
}
</style>