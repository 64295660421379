<template>
    <ul v-if="visible" class="breadcrumbs">
        <template v-for="(breadcrumb, index) of items" :key="index">
            <li>
                <router-link v-if="breadcrumb.route" :to="breadcrumb.route" class="breadcrumb">
                    {{breadcrumb.name}}
                </router-link>
                <span v-else class="breadcrumb">
                    {{breadcrumb.name}}
                </span>
            </li>
            <li v-if="(index + 1) < items.length" class="breadcrumb-seperator">
                <AngleIcon class="m-0"/>
            </li>
        </template>
    </ul>
</template>

<script>
import AngleIcon from "@/components/icons/AngleIcon.vue";

export default {
    name: "Breadcrumbs",
    components: {AngleIcon},
    props: {
        items: Array,
        visible: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        items: function(){
            this.updateSchemaBreadcrumbList();
        }
    },
    methods: {
        initSchemaBreadcrumbList(){
            const schemaBreadcrumbList = document.createElement('script');
            schemaBreadcrumbList.setAttribute('type', "application/ld+json");
            document.head.appendChild(schemaBreadcrumbList);

            window.schemaBreadcrumbList = schemaBreadcrumbList;
        },
        updateSchemaBreadcrumbList(){
            if(!('schemaBreadcrumbList' in window)) this.initSchemaBreadcrumbList();

            const itemList = [];
            for(let i = 0; i < this.items.length; i++){
                const item = this.items[i];

                const listItem = {
                    '@type': "ListItem",
                    position: i+1,
                    name: item.name
                };

                if(item.route){
                    const route = this.$router.resolve(item.route);
                    listItem.item = `https://${window.location.host}${route.fullPath}`;
                }

                itemList.push(listItem);
            }

            window.schemaBreadcrumbList.innerHTML = JSON.stringify({
                '@context': "http://schema.org",
                '@type': "BreadcrumbList",
                itemListElement: itemList,
            }, null, 4);
        }
    },
    mounted() {
        this.updateSchemaBreadcrumbList();
    },
    unmounted() {
        if('schemaBreadcrumbList' in window) {
            window.schemaBreadcrumbList.innerHTML = ``;
        }
    }
}
</script>

<style>
.breadcrumbs{
    list-style: none;
    margin: 0;
    padding: 0 0.8rem 1.2rem 2rem;

    display: flex;
    flex-wrap: wrap;

    font-size: 0.8rem;
    font-weight: 200;
}
.breadcrumbs .breadcrumb-seperator{
    display: flex;
    align-items: center;
    font-size: 0.72em;
    padding: 0 0.4em;
}
.breadcrumbs .breadcrumb{
    display: block;
    padding: 0.1em 0.4em;
    border-radius: 0.4em;
}
.breadcrumbs a.breadcrumb:hover{
    background-color: var(--content-background-2);
}
.breadcrumbs .breadcrumb-seperator,
.breadcrumbs li:last-child .breadcrumb{
    color: var(--like-dark-white);
}
</style>